import { nest } from 'd3-collection';
import { max, extent } from 'd3-array';
import { getNodeText } from '@testing-library/dom';



const countryData = [
  {
    "Score Type": "T1",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -14,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Poland",
    "Iso3": "POL",
    "Iso2": "PL",
    "Score": -17,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 3.75,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 5.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "United Kingdom",
    "Iso3": "GBR",
    "Iso2": "GB",
    "Score": 18.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 0.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Portugal",
    "Iso3": "PRT",
    "Iso2": "PT",
    "Score": 9.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -2.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Italy",
    "Iso3": "ITA",
    "Iso2": "IT",
    "Score": -6.5,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Spain",
    "Iso3": "ESP",
    "Iso2": "ES",
    "Score": -0.5,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -0.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Ireland",
    "Iso3": "IRL",
    "Iso2": "IE",
    "Score": 4.25,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -12.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 0.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Slovakia",
    "Iso3": "SVK",
    "Iso2": "SK",
    "Score": -7.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 5.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "The Netherlands",
    "Iso3": "NLD",
    "Iso2": "NL",
    "Score": 8.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 4,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": -1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "France",
    "Iso3": "FRA",
    "Iso2": "FR",
    "Score": 4,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -7,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -0.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Malta",
    "Iso3": "MLT",
    "Iso2": "MT",
    "Score": -5.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -8.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Romania",
    "Iso3": "ROU",
    "Iso2": "RO",
    "Score": -7.25,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 0.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Germany",
    "Iso3": "DEU",
    "Iso2": "DE",
    "Score": 0.25,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -0.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -12.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": 1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -3,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Czech Republic",
    "Iso3": "CZE",
    "Iso2": "CZ",
    "Score": -13.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": 2.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Austria",
    "Iso3": "AUT",
    "Iso2": "AT",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 0.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 0.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 5.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Denmark",
    "Iso3": "DNK",
    "Iso2": "DK",
    "Score": 7.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": 1,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Belgium",
    "Iso3": "BEL",
    "Iso2": "BE",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 2,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": -0.5,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 3,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Greece",
    "Iso3": "GRC",
    "Iso2": "GR",
    "Score": 1.25,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  },
  {
    "Score Type": "T1",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T2",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": 1
  },
  {
    "Score Type": "T3",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -1
  },
  {
    "Score Type": "T11",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "T6",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -0.5
  },
  {
    "Score Type": "A1",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Policy Transparency",
    "Cat_short": "trans",
    "Median": -2
  },
  {
    "Score Type": "Policy Transparency",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 2.25,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 1
  },
  {
    "Score Type": "T4",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "T5",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A3",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -1
  },
  {
    "Score Type": "A11",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A12",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "A13",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Undocumented Access",
    "Cat_short": "undoc",
    "Median": -0.5
  },
  {
    "Score Type": "Undocumented Access",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -5.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": -5.5
  },
  {
    "Score Type": "T9",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "T10",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Identification and Residency Requirements",
    "Cat_short": "idres",
    "Median": 1
  },
  {
    "Score Type": "Identification and Residency Requirements",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 0.5,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 2
  },
  {
    "Score Type": "T7",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 0.25
  },
  {
    "Score Type": "A18",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A19",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A21",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "A26",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 1,
    "Answer": "Yes",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": 1
  },
  {
    "Score Type": "A27",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -0.5,
    "Answer": "Unknown",
    "Cat_type": "Marginalized Access",
    "Cat_short": "marg",
    "Median": -0.5
  },
  {
    "Score Type": "Marginalized Access",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": 0,
    "Answer": "",
    "Cat_type": "Marginalized Access",
    "Cat_short": "subagg",
    "Median": 0.75
  },
  {
    "Score Type": "T12",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "No",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": 1
  },
  {
    "Score Type": "A5",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -2,
    "Answer": "NA",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "A31",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -1,
    "Answer": "Unknown",
    "Cat_type": "Privacy Guarantees",
    "Cat_short": "priv",
    "Median": -1
  },
  {
    "Score Type": "Privacy Guarantees",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -4,
    "Answer": "",
    "Cat_type": "Sub Aggregate",
    "Cat_short": "subagg",
    "Median": 0
  },
  {
    "Score Type": "Total Score",
    "Country": "Estonia",
    "Iso3": "EST",
    "Iso2": "EE",
    "Score": -6.75,
    "Answer": "",
    "Cat_type": "Aggregate",
    "Cat_short": "aggregate",
    "Median": -2.25
  }
]

const countryCodes = [
  {
    "country_name": "Ireland",
    "iso3": "IRL",
    "iso2": "IE",
    "grid_pos_x": 1,
    "grid_pos_y": 1,
    "blank": 0
  },
  {
    "country_name": "United Kingdom",
    "iso3": "GBR",
    "iso2": "GB",
    "grid_pos_x": 2,
    "grid_pos_y": 1,
    "blank": 0
  },
  {
    "country_name": "Denmark",
    "iso3": "DNK",
    "iso2": "DK",
    "grid_pos_x": 5,
    "grid_pos_y": 1,
    "blank": 0
  },
  {
    "country_name": "Estonia",
    "iso3": "EST",
    "iso2": "EE",
    "grid_pos_x": 7,
    "grid_pos_y": 1,
    "blank": 0
  },
  {
    "country_name": "Belgium",
    "iso3": "BEL",
    "iso2": "BE",
    "grid_pos_x": 3,
    "grid_pos_y": 2,
    "blank": 0
  },
  {
    "country_name": "The Netherlands",
    "iso3": "NLD",
    "iso2": "NL",
    "grid_pos_x": 4,
    "grid_pos_y": 2,
    "blank": 0
  },
  {
    "country_name": "Germany",
    "iso3": "DEU",
    "iso2": "DE",
    "grid_pos_x": 5,
    "grid_pos_y": 2,
    "blank": 0
  },
  {
    "country_name": "Poland",
    "iso3": "POL",
    "iso2": "PL",
    "grid_pos_x": 6,
    "grid_pos_y": 2,
    "blank": 0
  },
  {
    "country_name": "France",
    "iso3": "FRA",
    "iso2": "FR",
    "grid_pos_x": 3,
    "grid_pos_y": 3,
    "blank": 0
  },
  {
    "country_name": "Austria",
    "iso3": "AUT",
    "iso2": "AT",
    "grid_pos_x": 5,
    "grid_pos_y": 3,
    "blank": 0
  },
  {
    "country_name": "Czech Republic",
    "iso3": "CZE",
    "iso2": "CZ",
    "grid_pos_x": 6,
    "grid_pos_y": 3,
    "blank": 0
  },
  {
    "country_name": "Slovakia",
    "iso3": "SVK",
    "iso2": "SK",
    "grid_pos_x": 7,
    "grid_pos_y": 3,
    "blank": 0
  },
  {
    "country_name": "Portugal",
    "iso3": "PRT",
    "iso2": "PT",
    "grid_pos_x": 1,
    "grid_pos_y": 4,
    "blank": 0
  },
  {
    "country_name": "Spain",
    "iso3": "ESP",
    "iso2": "ES",
    "grid_pos_x": 2,
    "grid_pos_y": 4,
    "blank": 0
  },
  {
    "country_name": "Italy",
    "iso3": "ITA",
    "iso2": "IT",
    "grid_pos_x": 4,
    "grid_pos_y": 4,
    "blank": 0
  },
  {
    "country_name": "Romania",
    "iso3": "ROU",
    "iso2": "RO",
    "grid_pos_x": 7,
    "grid_pos_y": 4,
    "blank": 0
  },
  {
    "country_name": "Malta",
    "iso3": "MLT",
    "iso2": "MT",
    "grid_pos_x": 5,
    "grid_pos_y": 6,
    "blank": 0
  },
  {
    "country_name": "Greece",
    "iso3": "GRC",
    "iso2": "GR",
    "grid_pos_x": 7,
    "grid_pos_y": 5,
    "blank": 0
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 4,
    "grid_pos_y": 3,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 6,
    "grid_pos_y": 4,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 8,
    "grid_pos_y": 1,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 8,
    "grid_pos_y": 2,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 7,
    "grid_pos_y": 2,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 8,
    "grid_pos_y": 3,
    "blank": 1
  },
  {
    "country_name": "",
    "iso3": "",
    "iso2": "",
    "grid_pos_x": 8,
    "grid_pos_y": 4,
    "blank": 1
  }
]

const questions = [
  {
    "Score Type": "T1",
    "Score Category": "Policy Transparency",
    "Question Original": "1. National vaccination rollout document(s) is/are available publicly online as an official government publication",
    "Question Short": "National vaccination documents are available publicly online",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, national vaccination documents are publicly available online",
    "No": "No, national vaccination documents are not available online",
    "NA": "--",
    "Unknown": "--",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T2",
    "Score Category": "Policy Transparency",
    "Question Original": "2. National vaccination rollout document(s) has/have been explained in public statements, official declarations and commitments",
    "Question Short": "National authorities have explained the vaccine policies through public statements, declarations or commitments",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, national authorities have explained vaccine policies through public statements, declarations, committments",
    "No": "No, no public statement or declaration by national authorities explaining vaccine policies could be found",
    "NA": "--",
    "Unknown": "--",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T3",
    "Score Category": "Policy Transparency",
    "Question Original": "3. There is a publicly available national vaccination rollout budget (either total budget or detailed budget)",
    "Question Short": "Budget for vaccination campaign is available publicly",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, the budget for the national vaccination campaign is available online",
    "No": "No, the budget for the national vaccination campaign could not be found online",
    "NA": "--",
    "Unknown": "Unknown, it is unclear if the budget is available or not",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T11",
    "Score Category": "Policy Transparency",
    "Question Original": "11. National vaccination rollout document(s) includes a specific line of budget for undocumented populations",
    "Question Short": "There is a specific budget amount earmarked for the vaccination of undocumented people",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, the budget includes a specific amount earmarked for the vaccination of undocumented people",
    "No": "No, the budget does not include a specific amount earmarked for the vaccination of undocumented people",
    "NA": "NA, there is no budget available",
    "Unknown": "--",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": ""
  },
  {
    "Score Type": "T6",
    "Score Category": "Policy Transparency",
    "Question Original": "6. National vaccination rollout document(s) state/s the organisations/individuals who have contributed to its development",
    "Question Short": "The list of contributors to the development of vaccination policies is available publicly",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, there is a list available",
    "No": "No, there is no list available in the same location as the national vaccination policies",
    "NA": "--",
    "Unknown": "Unknown, it is unclear whether there is a list available in the same location as the national vaccination policies",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A1",
    "Score Category": "Policy Transparency",
    "Question Original": "1. If list of contributors to vaccination rollout document(s) is available, does it include any undocumented people or their representatives (eg. civil society organisations)?",
    "Question Short": "Undocumented people or organisations representing them have contributed to the development of the vaccination policies",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, undocumented people or their representitives advised on the vaccine strategy",
    "No": "No, undocumented people or their representatives did not advise on the vaccine strategy",
    "NA": "NA, no list of contributors to the national vaccination strategy was identified",
    "Unknown": "Unknown, there is a list but it is not clear who the people are/who they represent (the list does not include affiliations)",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T4",
    "Score Category": "Undocumented Access",
    "Question Original": "4. Language in national vaccine rollout documents is inclusive of undocumented populations",
    "Question Short": "Language in official written vaccination documents is inclusive of undocumented people",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, language can easily be interpreted as including undocumented populations, (ie \"everyone in the country, independent of legal status\")",
    "No": "No, undocumented populations are explicity excluded (ie \"only citizens, legal residents, work permit holders, official asylum seekers\")",
    "NA": "--",
    "Unknown": "Unknown, the language is vague (ie, \"residents\") or there is no national document that addresses these populations",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "T5",
    "Score Category": "Undocumented Access",
    "Question Original": "5. Language in official statements/declarations is inclusive of undocumented populations",
    "Question Short": "Language in official vaccination statements is inclusive of undocumented people",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, language can easily be interpreted as including undocumented populations, (ie \"everyone in the country, independent of legal status\")",
    "No": "No, undocumented populations are explicity excluded (ie \"only citizens, legal residents, work permit holders, official asylum seekers\")",
    "NA": "--",
    "Unknown": "Unknown, the language is vague (ie, \"residents\") or there are no public statements that address these populations",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "A3",
    "Score Category": "Undocumented Access",
    "Question Original": "3. Undocumented people are able to get vaccinated without any official identification according to the national vaccination rollout document (s) and public statements",
    "Question Short": "Undocumented people can access the vaccine without needing an ID",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, undocumented people are able to get vaccinated without official identification",
    "No": "No, undocumented people are not able to get vaccinated without official identification",
    "NA": "NA, undocumented people are excluded from the national vaccination programme",
    "Unknown": "Unknown, it is not clear whether undocumented people are able to get vaccinated without official identification",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -1
  },
  {
    "Score Type": "A11",
    "Score Category": "Undocumented Access",
    "Question Original": "11. Access to vaccines is guaranteed to undocumented populations on the same basis as documented residents including in terms of prioritization, when applicable, according to the national vaccination rollout document (s) and public statements",
    "Question Short": "Undocumented people have the same access to the vaccine as all others, including in terms of prioritisation",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, access to vaccine is guaranteed to undocumented populations on the same basis as documented residents, including in terms of prioritisation, when applicable",
    "No": "No, undocumented populations are denied access to the vaccine on the same basis as documented residents, either because they are not eligible for the vaccine or they go through a different process to access the vaccine",
    "NA": "NA, undocumented people are excluded from the national vaccination programme",
    "Unknown": "Unknown, it cannot be determined from the national vaccination documentation whether access to vaccine is guaranteed on the same basis as documented residents",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A12",
    "Score Category": "Undocumented Access",
    "Question Original": "12. Vaccines are available for free, or for full reimbursement for undocumented populations, on the same basis as for documented residents according to the national vaccination rollout document (s) and public statements",
    "Question Short": "Undocumented people can access the vaccine for free or with full reimbursement, the same as everybody else",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, vaccines are available for free, or for full reimbursement for undocumented populations, on the same basis as for documented residents",
    "No": "No, undocumented populations are denied access to free or a fully reimbursable vaccine on the same basis as documented residents, either because they are not eligible for the vaccine or they go through a different process to access the vaccine",
    "NA": "NA, undocumented people are excluded from the national vaccination programme",
    "Unknown": "Unknown, it cannot be determined from the national vaccinationation documentation whether vaccines are available for free or for full reimbursement on the same basis as documented residents",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A13",
    "Score Category": "Undocumented Access",
    "Question Original": "13. Type, or choice, of vaccine is available to undocumented populations on the same basis as documented residents according to the national vaccination rollout document(s) and public statements",
    "Question Short": "Undocumented people have access to the same vaccines as everybody else, including choice of vaccines where this is available",
    "Range": "Open and Accessible\nClosed Door",
    "Range Max": "Open and Accessible",
    "Range Min": "Closed Door",
    "Yes": "Yes, undocumented people have the same rights in terms of type of vaccines available, or when applicable, choice of vaccine (or lack of choice), as documented residents",
    "No": "No, undocumented populations do not have access to the type or choice of vaccine on the same basis as documented residents, either because they have been assigned a specific type of vaccine (while documented people are assigned multiple vaccines), or they are denied a choice of vaccine (while documented people have that choice)",
    "NA": "NA, undocumented people are excluded from the national vaccination programme",
    "Unknown": "Unknown, it cannot be determined from the national vaccination documentation whether the same type or choice of vaccine is available to undocumented people as for documented residents",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T9",
    "Score Category": "Identification and Residency Requirements",
    "Question Original": "9. National vaccination rollout document(s) include details on identification requirements necessary to get a vaccination",
    "Question Short": "National vaccination documentation specifies ID requirements needed to access the vaccine",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, national vaccination documentation mentions which ID requirements are necessary to get a vaccination",
    "No": "No, national vaccination documentation does not mention which ID requirements are necessary to get a vaccination",
    "NA": "--",
    "Unknown": "--",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T10",
    "Score Category": "Identification and Residency Requirements",
    "Question Original": "10. National vaccination rollout document(s) include details on residency requirements necessary to get a vaccination",
    "Question Short": "National vaccination documentation specifies residency requirements needed to access the vaccine",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, national vaccination documentation mentions which residency requirements are necessary to get a vaccination",
    "No": "No, national vaccination documentation does not mention which residency requirements are necessary to get a vaccination",
    "NA": "--",
    "Unknown": "Unknown, it is not clear if residency requirements are included in the national vaccination documentation",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T7",
    "Score Category": "Marginalised Access",
    "Question Original": "7. National vaccination rollout document(s) address/es housing insecure populations (this may or may not directly include migrant communities)",
    "Question Short": "Housing insecure people have access to the vaccines",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, housing insecure populations have access to the vaccines",
    "No": "No, housing insecure populations do not have access to the vaccines",
    "NA": "--",
    "Unknown": "Unknown, it is not clear whether housing insecure people have access to the vaccines",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A18",
    "Score Category": "Marginalised Access",
    "Question Original": "18. Incarcerated populations and/or populations with other freedom of movement limitations such as those in detention centres are included in the vaccination program, according to the national vaccination rollout document(s) and public statements",
    "Question Short": "People in detention and with other restrictions to freedom of movement have access to the vaccines",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, incarcerated populations and/or populations with other freedom of movement limitations such as those in detention centres have access to the vaccines",
    "No": "No, incarcerated populations and/or populations with other freedom of movement limitations such as those in detention centres do not have access to the vaccines",
    "NA": "--",
    "Unknown": "Unknown, it is not clear if incarcerated populations and/or populations with other freedom of movement limitations such as those in detention centres have access to the vaccines. For example, national vaccination documentation mentions \"vulnerable groups\", \"people living in precarious conditions\" or workers in detention centers without specifying residents",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A19",
    "Score Category": "Marginalised Access",
    "Question Original": "19. Resources are allocated to ensure people without internet access are able to register for the vaccine according to the national vaccination rollout document(s) and public statements",
    "Question Short": "People without Internet access have access to the vaccines",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, resources are allocated to ensure people without internet access are able to access the vaccine. The documentation provides for other options, for example, a phone number to call, the possibility to book an appointment through a pharmacy/GP, walk-ins, etc.",
    "No": "No, resources are not  allocated to ensure people without internet access are able to access the vaccine and everyone must go through an online system",
    "NA": "--",
    "Unknown": "Unknown, it cannot be determined whether resources have been allocated to ensure people without internet access are able to access the vaccine",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A21",
    "Score Category": "Marginalised Access",
    "Question Original": "21. Resources are allocated for mobile interventions according to the national vaccination rollout document(s) and public statements",
    "Question Short": "Mobile vaccination centres are planned",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, resources are allocated for mobile interventions",
    "No": "No, everyone must obtain the vaccine in fixed physical locations",
    "NA": "--",
    "Unknown": "Unknown, it cannot be determined whether resources have been allocated for mobile interventions",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A26",
    "Score Category": "Marginalised Access",
    "Question Original": "26. Specific programmes to address misinformation and vaccine hesitancy are foreseen, according to the national vaccination rollout document(s) and public statements",
    "Question Short": "National programmes will address misinformation and vaccine hesitancy",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, specific programmes, such as FAQs, community information sessions and informational materials distributed on or offline address misinformation and vaccine hesitancy",
    "No": "No, there are no specific programmes to address misinformation and vaccine hesitancy",
    "NA": "--",
    "Unknown": "Unknown, it cannot be determined whether specific programmes to address misinformation and vaccine hesitancy exist",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A27",
    "Score Category": "Marginalised Access",
    "Question Original": "27. Specific resources (language support, community action plan) for outreach efforts to minority communities are provided for according to the national vaccination rollout document(s) and public statements",
    "Question Short": "There are resources for outreach efforts to minority communities (for example, specific language support and action plans)",
    "Range": "Inclusive\nExclusionary",
    "Range Max": "Inclusive",
    "Range Min": "Exclusionary",
    "Yes": "Yes, there are specific resources (language support, community action plans) for outreach efforts to minority communities",
    "No": "No, there is no provision for specific resources (language support, community action plans) for outreach efforts to minority communities. All resources are only available in the official national language(s)",
    "NA": "--",
    "Unknown": "Unknown, it cannot be determined whether specific resources (language support, community action plans) for outreach efforts to minority communities are provided",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T12",
    "Score Category": "Privacy Guarantees",
    "Question Original": "12. National vaccination rollout document(s) include/s data sharing and/or data retention and/or data protection policy/ies",
    "Question Short": "Data sharing, data retention or data protection policies are clear",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, there are clear policies on data sharing, data retention, or data protection",
    "No": "No, there are no policies on data sharing, data retention or data protection",
    "NA": "--",
    "Unknown": "Unknown, it is unclear whether any data sharing, data retention, or data protection policies are available",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "A5",
    "Score Category": "Privacy Guarantees",
    "Question Original": "5. National vaccination rollout document(s)/public statement provide assurances that data collected during vaccination will not be shared outside of health authorities",
    "Question Short": "There are official assurances that data collected during vaccination will not be shared outside of health authorities",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, there are official assurances that data collected during vaccination will not be shared outside of health authorities",
    "No": "No, there are no official assurances that data collected during vaccination will not be shared outside of health authorities",
    "NA": "NA, there are no data sharing, data retention, or data protection policies",
    "Unknown": "Unknown, it is not clear whether data collected during vaccinations will be shared outside of health authorities",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -1
  },
  {
    "Score Type": "A31",
    "Score Category": "Privacy Guarantees",
    "Question Original": "31. The certificate of vaccination mentions the place where the vaccination took place (either a specific vaccination site location, region or the country), according to the national vaccine rollout document(s) and public statements",
    "Question Short": "Certificate of vaccination mentions the place the person was vaccinated (the specific location, or the country)",
    "Range": "Transparent\nOpaque",
    "Range Max": "Transparent",
    "Range Min": "Opaque",
    "Yes": "Yes, the certificate of vaccination mentions the place where the vaccination took place (either a specific vaccination site location, region or the country)",
    "No": "No, the certificate of vaccination omits the specific vaccination site location (either a specific vaccination site location, region or the country)",
    "NA": "--",
    "Unknown": "Unknown, there is no information available on whether the certificate of vaccination mentions the location where the vaccination took place (either a specific vaccination site location, region or the country)",
    "Yes_Score": -1,
    "No_Score": 1,
    "NA_Score": "",
    "Unknown_Score": -1
  }
]

const questionsEL = [
  {
    "Score Type": "T1",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Τα εθνικά έγγραφα εμβολιασμού είναι διαθέσιμα στο διαδίκτυο",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, τα εθνικά έγγραφα εμβολιασμού είναι δημόσια διαθέσιμα στο διαδίκτυο",
    "No": "Όχι, τα εθνικά έγγραφα εμβολιασμού δεν είναι δημόσια διαθέσιμα στο διαδίκτυο",
    "NA": "-",
    "Unknown": "-",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T2",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Οι εθνικές αρχές έχουν εξηγήσει τις πολιτικές εμβολιασμού μέσω δημόσιων ανακοινώσεων, δηλώσεων ή δεσμεύσεων",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, οι εθνικές αρχές έχουν εξηγήσει τις πολιτικές εμβολιασμού μέσω δημόσιων ανακοινώσεων, δηλώσεων, δεσμεύσεων",
    "No": "Όχι, δεν βρέθηκε καμία δημόσια δήλωση ή διακήρυξη από τις εθνικές αρχές",
    "NA": "-",
    "Unknown": "-",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T3",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Ο προϋπολογισμός για την εκστρατεία εμβολιασμού είναι διαθέσιμος δημόσια",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, ο προϋπολογισμός για την εθνική εκστρατεία εμβολιασμού είναι διαθέσιμος στο διαδίκτυο",
    "No": "Όχι, ο προϋπολογισμός για την εθνική εκστρατεία εμβολιασμού δεν βρέθηκε στο διαδίκτυο",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές αν ο προϋπολογισμός είναι διαθέσιμος ή όχι",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T11",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Υπάρχει ένα συγκεκριμένο ποσό του προϋπολογισμού το οποίο προορίζεται για τον εμβολιασμό ατόμων χωρίς έγγραφα",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, ο προϋπολογισμός περιλαμβάνει συγκεκριμένο ποσό που προορίζεται για τον εμβολιασμό ατόμων χωρίς έγγραφα",
    "No": "Όχι, ο προϋπολογισμός δεν περιλαμβάνει συγκεκριμένο ποσό που προορίζεται για τον εμβολιασμό ατόμων χωρίς έγγραφα",
    "NA": "Ο προϋπολογισμός δεν είναι διαθέσιμος",
    "Unknown": "-",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": ""
  },
  {
    "Score Type": "T6",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Ο κατάλογος των συμμετεχόντων στη διαβούλευση και στην ανάπτυξη πολιτικών εμβολιασμού είναι διαθέσιμος στο κοινό",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, υπάρχει διαθέσιμη λίστα",
    "No": "Όχι, δεν υπάρχει διαθέσιμη λίστα στον ίδιο ιστότοπο με τις εθνικές πολιτικές εμβολιασμού",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές εάν υπάρχει διαθέσιμη λίστα στον ίδιο ιστότοπο με τις εθνικές πολιτικές εμβολιασμού",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A1",
    "Score Category": "Policy Transparency",
    "Question Original": "",
    "Question Short": "Άτομα χωρίς έγγραφα ή οργανώσεις που τα εκπροσωπούν έχουν συμβάλει στην ανάπτυξη των πολιτικών εμβολιασμού",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι. Άτομα χωρίς έγγραφα ή οργανώσεις που τα εκπροσωπούν έχουν συμβάλει στην ανάπτυξη των πολιτικών εμβολιασμού",
    "No": "Όχι. Άτομα χωρίς έγγραφα ή οργανώσεις που τα εκπροσωπούν δεν έχουν συμβάλει στην ανάπτυξη των πολιτικών εμβολιασμού",
    "NA": "Μη διαθέσιμο. Δεν εντοπίστηκε κατάλογος των συμμετεχόντων στην εθνική στρατηγική εμβολιασμού",
    "Unknown": "Άγνωστο. Υπάρχει ένας κατάλογος υπευθύνων αλλά δεν είναι ξεκάθαρο ποιοι είναι ή ποιους εκπροσωπούν (η λίστα δεν περιλαμβάνει τέτοιες πληροφορίες)",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T4",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Η γλώσσα στα επίσημα γραπτά έγγραφα εμβολιασμού είναι συμπεριληπτική ως προς τα άτομα χωρίς έγγραφα",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι. Η γλώσσα είναι συμπεριληπτική ως προς τα άτομα χωρίς έγγραφα (δηλαδή, «όλοι στην χώρα, ανεξάρτητα από το νομικό πλαίσιο»)",
    "No": "Όχι. Τα άτομα χωρίς έγγραφα αποκλείονται ρητά (δηλαδή, «μόνο πολίτες, νόμιμοι κάτοικοι, εργαζόμενοι, επίσημοι αιτούντες ασύλου»)",
    "NA": "-",
    "Unknown": "Άγνωστο. Η γλώσσα είναι ασαφής (δηλαδή, «κάτοικοι») ή δεν υπάρχει εθνικό έγγραφο που να απευθύνεται σε αυτούς τους πληθυσμούς",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "T5",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Η γλώσσα στις επίσημες δηλώσεις εμβολιασμού είναι συμπεριληπτική ως προς τα άτομα χωρίς έγγραφα",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, η γλώσσα είναι συμπεριληπτική ως προς τα άτομα χωρίς έγγραφα (δηλαδή, «όλοι στην χώρα, ανεξάρτητα νομικού πλαισίου»)",
    "No": "Όχι, τα άτομα χωρίς έγγραφα αποκλείονται ρητά (δηλαδή, «μόνο πολίτες, νόμιμοι κάτοικοι, εργαζόμενοι, επίσημοι αιτούντες ασύλου»)",
    "NA": "-",
    "Unknown": "Άγνωστο, η γλώσσα είναι ασαφής (δηλαδή, «κάτοικοι») ή δεν υπάρχει εθνικό έγγραφο που να απευθύνεται σε αυτούς τους πληθυσμούς",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "A3",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Άτομα χωρίς έγγραφα μπορούν να έχουν πρόσβαση στο εμβόλιο χωρίς να χρειάζονται ταυτότητα",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, τα άτομα χωρίς έγγραφα μπορούν να εμβολιαστούν χωρίς επίσημη ταυτότητα",
    "No": "Όχι, τα άτομα χωρίς έγγραφα δεν μπορούν να εμβολιαστούν χωρίς επίσημη ταυτότητα",
    "NA": "Μη διαθέσιμο, τα άτομα χωρίς έγγραφα αποκλείονται από το εθνικό πρόγραμμα εμβολιασμού",
    "Unknown": "Άγνωστο, δεν είναι σαφές αν τα άτομα χωρίς έγγραφα μπορούν να εμβολιαστούν χωρίς επίσημη ταυτότητα",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -1
  },
  {
    "Score Type": "A11",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Τα άτομα χωρίς έγγραφα έχουν την ίδια πρόσβαση στο εμβόλιο με όλα τα άλλα, συμπεριλαμβανομένης και της ιεράρχησης προτεραιοτήτων",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, η πρόσβαση στο εμβόλιο είναι εγγυημένη στα άτομα χωρίς έγγραφα στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους, συμπεριλαμβανομένης και της ιεράρχησης προτεραιοτήτων, όταν αυτή εφαρμόζεται",
    "No": "Όχι, τα άτομα χωρίς έγγραφα δεν έχουν πρόσβαση στο εμβόλιο στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους, είτε επειδή δεν δικαιούνται το εμβόλιο, είτε επειδή περνούν από διαφορετική διαδικασία για να έχουν πρόσβαση στο εμβόλιο",
    "NA": "Μη διαθέσιμο. Tα άτομα χωρίς έγγραφα αποκλείονται από το εθνικό πρόγραμμα εμβολιασμού",
    "Unknown": "Άγνωστο, δεν μπορεί να προσδιοριστεί από τα εθνικά έγγραφα εμβολιασμού εάν η πρόσβαση στο εμβόλιο είναι εγγυημένη στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους",
    "Yes_Score": 1,
    "No_Score": -2,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A12",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Άτομα χωρίς έγγραφα μπορούν να έχουν πρόσβαση στο εμβόλιο δωρεάν ή με επιστροφή της δαπάνης τους, όπως όλα τα άλλα",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, τα εμβόλια είναι δωρεάν ή δίνεται πλήρης αποζημίωση στα άτομα χωρίς έγγραφα στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους",
    "No": "Όχι, τα άτομα χωρίς έγγραφα δεν έχουν δωρεάν πρόσβαση στο εμβόλιο ή δεν τους επιστρέφεται η δαπάνη για το εμβόλιο στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους, είτε επειδή δεν δικαιούνται να εμβολιαστούν, είτε επειδή περνούν από διαφορετική διαδικασία για να έχουν πρόσβαση στο εμβόλιο",
    "NA": "Μη διαθέσιμο. Τα άτομα χωρίς έγγραφα αποκλείονται από το εθνικό πρόγραμμα εμβολιασμού",
    "Unknown": "Άγνωστο. Δεν μπορεί να προσδιοριστεί από τα εθνικά έγγραφα εμβολιασμού εάν τα εμβόλια είναι διαθέσιμα δωρεάν ή με επιστροφή της δαπάνης τους με τους ίδιους όρους με τους εγγεγραμμένους κατοίκους",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A13",
    "Score Category": "Undocumented Access",
    "Question Original": "",
    "Question Short": "Άτομα χωρίς έγγραφα έχουν πρόσβαση στα ίδια εμβόλια με όλους τους άλλους, συμπεριλαμβανομένης της επιλογής εμβολίων όπου αυτή είναι διαθέσιμη",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, τα άτομα χωρίς έγγραφα έχουν τα ίδια δικαιώματα όσον αφορά το είδος των διαθέσιμων εμβολίων ή την επιλογή εμβολίου (και την απουσία αυτής) όπου εφαρμόζεται, στον ίδιο βαθμό με τους εγγεγραμμένους κατοίκους",
    "No": "Όχι, τα άτομα χωρίς έγγραφα δεν έχουν πρόσβαση στο είδος ούτε στην επιλογή του εμβολίου στον ίδιο βαθμό με τους εγγεγραμμένους πολίτες, είτε επειδή έχει καθοριστεί συγκεκριμένος τύπος εμβολίου γι’ αυτούς (ενώ για τους εγγεγραμμένους πολίτες υπάρχει η επιλογή διαφορετικών εμβολίων) είτε επειδή δεν έχουν επιλογή εμβολιασμού (σε αντίθεση με τους εγγεγραμμένους πολίτες)",
    "NA": "-",
    "Unknown": "Άγνωστο. Δεν μπορεί να προσδιοριστεί από τα εθνικά έγγραφα εμβολιασμού εάν ο ίδιος τύπος ή η επιλογή εμβολίου είναι στην διάθεση των ατόμων χωρίς έγγραφα όπως είναι και στους εγγεγραμμένους κατοίκους",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T9",
    "Score Category": "Identification and Residency Requirements",
    "Question Original": "",
    "Question Short": "Τα εθνικά έγγραφα εμβολιασμού καθορίζουν τις απαιτήσεις ταυτότητας που χρειάζεται για την πρόσβαση στο εμβόλιο",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, τα εθνικά έγγραφα εμβολιασμού αναφέρουν ποιες απαιτήσεις ταυτότητας είναι απαραίτητες για τον εμβολιασμό",
    "No": "Όχι, τα εθνικά έγγραφα εμβολιασμού δεν αναφέρουν ποιες απαιτήσεις ταυτότητας είναι απαραίτητες για τον εμβολιασμό",
    "NA": "-",
    "Unknown": "-",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": ""
  },
  {
    "Score Type": "T10",
    "Score Category": "Identification and Residency Requirements",
    "Question Original": "",
    "Question Short": "Τα εθνικά έγγραφα εμβολιασμού καθορίζουν τις απαιτήσεις διαμονής που απαιτούνται για την πρόσβαση στο εμβόλιο",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, τα εθνικά έγγραφα εμβολιασμού αναφέρουν ποιες απαιτήσεις διαμονής είναι απαραίτητες για τον εμβολιασμό",
    "No": "Όχι, τα εθνικά έγγραφα εμβολιασμού δεν αναφέρουν ποιες απαιτήσεις διαμονής είναι απαραίτητες για τον εμβολιασμό",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές αν συμπεριλαμβάνονται οι απαιτήσεις διαμονής στα εθνικά έγγραφα εμβολιασμού",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T7",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Οι άνθρωποι χωρίς σταθερή στέγη έχουν πρόσβαση στα εμβόλια",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, οι άνθρωποι χωρίς σταθερή στέγη έχουν πρόσβαση στα εμβόλια",
    "No": "Όχι, οι άνθρωποι χωρίς σταθερή στέγη δεν έχουν πρόσβαση στα εμβόλια",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές εάν τα άτομα χωρίς σταθερή στέγη έχουν πρόσβαση στα εμβόλια",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A18",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Άτομα που βρίσκονται υπό κράτηση και με άλλους περιορισμούς στην ελεύθερη κυκλοφορία έχουν πρόσβαση στα εμβόλια",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, οι έγκλειστοι πληθυσμοί ή/και πληθυσμοί με άλλους περιορισμούς στην ελευθερία μετακίνησης, όπως αυτοί που βρίσκονται σε κέντρα κράτησης, έχουν πρόσβαση στα εμβόλια",
    "No": "Όχι, οι έγκλειστοι πληθυσμοί ή/και πληθυσμοί με άλλους περιορισμούς στην ελευθερία μετακίνησης, όπως αυτοί που βρίσκονται σε κέντρα κράτησης, δεν έχουν πρόσβαση στα εμβόλια",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές εάν οι έγκλειστοι πληθυσμοί ή/και πληθυσμοί με άλλους περιορισμούς στην ελευθερία μετακίνησης, όπως αυτοί που βρίσκονται σε κέντρα κράτησης, έχουν πρόσβαση στα εμβόλια. Για παράδειγμα, τα εθνικά έγγραφα εμβολιασμού αναφέρουν «ευπαθείς ομάδες», «ανθρώπους που μένουν υπό αβέβαιες συνθήκες» ή εργάτες σε κέντρα κράτησης χωρίς να προσδιορίζεται η κατοικία τους",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A19",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Άνθρωποι χωρίς πρόσβαση στο διαδίκτυο έχουν πρόσβαση στα εμβόλια",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, έχουν διατεθεί πόροι για να διασφαλιστεί ότι άνθρωποι χωρίς πρόσβαση στο διαδίκτυο μπορούν να έχουν πρόσβαση στο εμβόλιο. Τα έγγραφα παρέχουν και άλλες επιλογές, για παράδειγμα τηλέφωνα επικοινωνίας, τη δυνατότητα προγραμματισμού ραντεβού μέσω φαρμακείου, ώρες επίσκεψης χωρίς ραντεβού κ.α.",
    "No": "Όχι, δεν έχουν διατεθεί πόροι για να διασφαλιστεί ότι άνθρωποι χωρίς πρόσβαση στο διαδίκτυο μπορούν να έχουν πρόσβαση στο εμβόλιο και όλοι πρέπει να χρησιμοποιούν το διαδικτυακό σύστημα",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν μπορεί να προσδιοριστεί εάν έχουν διατεθεί πόροι για να διασφαλιστεί ότι άτομα χωρίς πρόσβαση στο διαδίκτυο μπορούν να έχουν πρόσβαση στο εμβόλιο",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A21",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Έχουν σχεδιαστεί κινητές μονάδες εμβολιασμού",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, έχουν διατεθεί πόροι για κινητές μονάδες παρέμβασης",
    "No": "Όχι, το εμβόλιο πρέπει να χορηγηθεί σε όλους σε σταθερές φυσικές τοποθεσίες",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν μπορεί να προσδιοριστεί εάν έχουν διατεθεί πόροι για κινητές μονάδες παρέμβασης",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A26",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Τα εθνικά προγράμματα θα αντιμετωπίσουν την παραπληροφόρηση και τον δισταγμό έναντι των εμβολίων",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, υπάρχουν συγκεκριμένα προγράμματα, όπως «Συχνές Ερωτήσεις», συνεδρίες ενημέρωσης της κοινότητας και ενημερωτικό υλικό που διανέμεται ονλάιν ή εκτός διαδικτύου με σκοπό την αντιμετώπιση της παραπληροφόρησης και της επιφυλακτικότητας απέναντι στον εμβολιασμό",
    "No": "Όχι, δεν υπάρχουν ειδικά προγράμματα με σκοπό την αντιμετώπιση της παραπληροφόρησης και της επιφυλακτικότητας απέναντι στον εμβολιασμό",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν μπορεί να προσδιοριστεί εάν υπάρχουν ειδικά προγράμματα με σκοπό την αντιμετώπιση της παραπληροφόρησης και της επιφυλακτικότητας απέναντι στον εμβολιασμό",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "A27",
    "Score Category": "Marginalised Access",
    "Question Original": "",
    "Question Short": "Υπάρχουν πόροι με σκοπό την προσέγγιση των μειονοτικών κοινοτήτων (για παράδειγμα, ειδική γλωσσική υποστήριξη και σχέδια δράσης)",
    "Range": "Ανοιχτή και Προσβάσιμη\nΚλειστή",
    "Range Max": "Ανοιχτή και Προσβάσιμη",
    "Range Min": "Κλειστή",
    "Yes": "Ναι, υπάρχουν ειδικοί πόροι (γλωσσική υποστήριξη, σχέδια δράσης για την κοινότητα) με σκοπό την προσέγγιση των μειονοτικών ομάδων",
    "No": "Όχι, δεν υπάρχουν ειδικοί πόροι (γλωσσική υποστήριξη, σχέδια δράσης για την κοινότητα) με σκοπό την προσέγγιση των μειονοτικών ομάδων. Όλοι οι πόροι είναι διαθέσιμοι στην εθνική γλώσσα(-ες)",
    "NA": "-",
    "Unknown": "Άγνωστο. Δεν μπορεί να προσδιοριστεί εάν παρέχονται ειδικοί πόροι (γλωσσική υποστήριξη, σχέδια δράσης για την κοινότητα) με σκοπό την προσέγγιση των μειονοτικών ομάδων",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -0.5
  },
  {
    "Score Type": "T12",
    "Score Category": "Privacy Guarantees",
    "Question Original": "",
    "Question Short": "Οι πολιτικές κοινής χρήσης προσωπικών δεδομένων, διατήρησης δεδομένων ή προστασίας δεδομένων είναι σαφείς",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, υπάρχουν σαφείς πολιτικές σχετικά με την κοινή χρήση προσωπικών δεδομένων, τη διατήρηση δεδομένων ή την προστασία δεδομένων",
    "No": "Όχι, δεν υπάρχουν σαφείς πολιτικές σχετικά με την κοινή χρήση προσωπικών δεδομένων, τη διατήρηση δεδομένων ή την προστασία δεδομένων",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν είναι σαφές αν υπάρχουν σαφείς πολιτικές σχετικά με την κοινή χρήση προσωπικών δεδομένων, τη διατήρηση δεδομένων ή την προστασία δεδομένων",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": "",
    "Unknown_Score": -1
  },
  {
    "Score Type": "A5",
    "Score Category": "Privacy Guarantees",
    "Question Original": "",
    "Question Short": "Υπάρχουν επίσημες διαβεβαιώσεις ότι τα δεδομένα που συλλέγονται κατά τον εμβολιασμό δεν θα κοινοποιηθούν εκτός των υγειονομικών αρχών",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, υπάρχουν επίσημες διαβεβαιώσεις ότι τα δεδομένα που συλλέγονται κατά τον εμβολιασμό δεν θα κοινοποιηθούν εκτός των υγειονομικών αρχών",
    "No": "Όχι, δεν υπάρχουν επίσημες διαβεβαιώσεις ότι τα δεδομένα που συλλέγονται κατά τον εμβολιασμό δεν θα κοινοποιηθούν εκτός των υγειονομικών αρχών",
    "NA": "Μη διαθέσιμο. Δεν υπάρχουν πολιτικές σχετικά με την κοινή χρήση δεδομένων, τη διατήρηση δεδομένων ή την προστασία δεδομένων",
    "Unknown": "Άγνωστο. Δεν είναι σαφές αν τα δεδομένα που συλλέγονται κατά τον εμβολιασμό δεν θα κοινοποιηθούν εκτός των υγειονομικών αρχών",
    "Yes_Score": 1,
    "No_Score": -1,
    "NA_Score": -2,
    "Unknown_Score": -1
  },
  {
    "Score Type": "A31",
    "Score Category": "Privacy Guarantees",
    "Question Original": "",
    "Question Short": "Το πιστοποιητικό εμβολιασμού αναφέρει το μέρος που εμβολιάστηκε το άτομο (τη συγκεκριμένη τοποθεσία ή τη χώρα)",
    "Range": "Διαφανής\nΑδιαφανής",
    "Range Max": "Διαφανής",
    "Range Min": "Αδιαφανής",
    "Yes": "Ναι, το πιστοποιητικό εμβολιασμού αναφέρει την τοποθεσία όπου έλαβε χώρα ο εμβολιασμός (είτε μια συγκεκριμένη τοποθεσία εμβολιασμού, περιοχή ή χώρα)",
    "No": "Όχι, το πιστοποιητικό εμβολιασμού παραλείπει την τοποθεσία του συγκεκριμένου τόπου εμβολιασμού (είτε μια συγκεκριμένη τοποθεσία εμβολιασμού, περιοχή ή χώρα)",
    "NA": "-",
    "Unknown": "Άγνωστο, δεν υπάρχουν διαθέσιμες πληροφορίες σχετικά με το αν το πιστοποιητικό εμβολιασμού αναφέρει την τοποθεσία όπου ο εμβολιασμός έλαβε χώρα (είτε μια συγκεκριμένη τοποθεσία εμβολιασμού, περιοχή ή χώρα)",
    "Yes_Score": -1,
    "No_Score": 1,
    "NA_Score": "",
    "Unknown_Score": -1
  }
]

const countryProfiles = {
  BEL: {
    country: "Belgium",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Identification and Residency Requirements",
      "Marginalized Access",
    ],
    closed_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access", "Marginalized Access"],
    covid_performance: "worse",
    vax_percent: "74.64",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "225.2342",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "4274.0495947233",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "0",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.454545",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    iso3: "BEL",
    overall_score: "-5.5",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-5.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "1",
    "score-Privacy Guarantees": "-4",
    overall_confidence: "0.543478260869565",
  },
  CZE: {
    country: "Czech Republic",
    overall_rank: "Closed Doors",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: ["Identification and Residency Requirements"],
    closed_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: [],
    covid_performance: "worse",
    vax_percent: "57.68",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "289.5505",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "1536.0618896517",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.785714",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.818182",
    "CScore-Privacy Guarantees": "0.5",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "-13.75",
    "score-Policy Transparency": "-0.25",
    "score-Undocumented Access": "-12.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "0",
    "score-Privacy Guarantees": "-3",
    overall_confidence: "0.804347826086957",
  },
  DNK: {
    country: "Denmark",
    overall_rank: "Open and Accessible",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Undocumented Access",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    closed_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
    ],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "76.96",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "47.2365",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "5408.5886425803",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.727273",
    "CScore-Undocumented Access": "0.357143",
    "CScore-Identification and Residency Requirements": "0.5",
    "CScore-Marginalized Access": "1",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "7.75",
    "score-Policy Transparency": "0.25",
    "score-Undocumented Access": "-0.5",
    "score-Identification and Residency Requirements": "0.5",
    "score-Marginalized Access": "5.5",
    "score-Privacy Guarantees": "2",
    overall_confidence: "0.673913043478261",
  },
  EST: {
    country: "Estonia",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: ["Policy Transparency"],
    closed_categories: [
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "59.17",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "122.0204",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "1351.0828125",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0",
    "CScore-Identification and Residency Requirements": "0.5",
    "CScore-Marginalized Access": "0.818182",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "-6.75",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-5.5",
    "score-Identification and Residency Requirements": "0.5",
    "score-Marginalized Access": "0",
    "score-Privacy Guarantees": "-4",
    overall_confidence: "0.565217391304348",
  },
  FRA: {
    country: "France",
    overall_rank: "Open and Accessible",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: ["Undocumented Access", "Marginalized Access"],
    closed_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "75.61",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "176.0162",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "4080.3628710981",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "0.357143",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.818182",
    "CScore-Privacy Guarantees": "0.666667",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "4",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-1",
    "score-Identification and Residency Requirements": "0",
    "score-Marginalized Access": "4",
    "score-Privacy Guarantees": "0",
    overall_confidence: "0.717391304347826",
  },
  DEU: {
    country: "Germany",
    overall_rank: "Open and Accessible",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
      "Privacy Guarantees",
    ],
    closed_categories: ["Undocumented Access", "Marginalized Access"],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "68.55",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "115.2926",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "4760.8158691428",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.636364",
    "CScore-Privacy Guarantees": "0.666667",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "0.25",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-5.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "0.5",
    "score-Privacy Guarantees": "1",
    overall_confidence: "0.543478260869565",
  },
  AUT: {
    country: "Austria",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Identification and Residency Requirements",
      "Marginalized Access",
    ],
    closed_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "64.77",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "127.335",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "4634.0002001964",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "0",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.636364",
    "CScore-Privacy Guarantees": "1",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "-4",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-5.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "2.5",
    "score-Privacy Guarantees": "-4",
    overall_confidence: "0.608695652173913",
  },
  GRC: {
    country: "Greece",
    overall_rank: "Open and Accessible",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
      "Privacy Guarantees",
    ],
    closed_categories: ["Undocumented Access", "Marginalized Access"],
    incomplete_categories: ["Undocumented Access", "Marginalized Access"],
    covid_performance: "worse",
    vax_percent: "63.73",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "157.7611",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "1363.2029150358",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.142857",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.272727",
    "CScore-Privacy Guarantees": "1",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    overall_score: "1.25",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-5.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "-0.5",
    "score-Privacy Guarantees": "3",
    overall_confidence: "0.543478260869565",
  },
  IRL: {
    country: "Ireland",
    overall_rank: "Open and Accessible",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: [
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    closed_categories: ["Policy Transparency"],
    incomplete_categories: ["Undocumented Access"],
    covid_performance: "better",
    vax_percent: "76.36",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "110.2169",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "4775.4915966786",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.357143",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "1",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "4.25",
    "score-Policy Transparency": "-0.25",
    "score-Undocumented Access": "-1",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "1.5",
    "score-Privacy Guarantees": "2",
    overall_confidence: "0.760869565217391",
  },
  ITA: {
    country: "Italy",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: ["Undocumented Access"],
    closed_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access", "Marginalized Access"],
    covid_performance: "worse",
    vax_percent: "77.07",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "219.361499999999",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "2600.4263891607",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "0.214286",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.454545",
    "CScore-Privacy Guarantees": "1",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    overall_score: "-6.5",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-2.5",
    "score-Identification and Residency Requirements": "0",
    "score-Marginalized Access": "-1",
    "score-Privacy Guarantees": "-4",
    overall_confidence: "0.630434782608696",
  },
  NLD: {
    country: "The Netherlands",
    overall_rank: "Open and Accessible",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Marginalized Access",
    ],
    closed_categories: ["Privacy Guarantees"],
    incomplete_categories: [],
    covid_performance: "better",
    vax_percent: "75.43",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "110.7838",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "4616.6851611339",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.785714",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "1",
    "CScore-Privacy Guarantees": "0.5",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "8.75",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-1",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "5.5",
    "score-Privacy Guarantees": "0",
    overall_confidence: "0.847826086956522",
  },
  POL: {
    country: "Poland",
    overall_rank: "Closed Doors",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: ["Identification and Residency Requirements"],
    closed_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: [],
    covid_performance: "worse",
    vax_percent: "53.26",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "205.7306",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "851.5000213638",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "1",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.818182",
    "CScore-Privacy Guarantees": "1",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "-17",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-14",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "-2",
    "score-Privacy Guarantees": "-4",
    overall_confidence: "0.956521739130435",
  },
  PRT: {
    country: "Portugal",
    overall_rank: "Open and Accessible",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Privacy Guarantees",
    ],
    closed_categories: ["Marginalized Access"],
    incomplete_categories: [],
    covid_performance: "better",
    vax_percent: "88.63",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "179.0828",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "1927.2010180626",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.714286",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.636364",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "9.75",
    "score-Policy Transparency": "1.25",
    "score-Undocumented Access": "4",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "0.5",
    "score-Privacy Guarantees": "2",
    overall_confidence: "0.782608695652174",
  },
  ROU: {
    country: "Romania",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: ["Policy Transparency", "Marginalized Access"],
    closed_categories: [
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Privacy Guarantees",
    ],
    incomplete_categories: ["Undocumented Access", "Marginalized Access"],
    covid_performance: "worse",
    vax_percent: "31.48",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "266.6071",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "597.9113232411",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.357143",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.454545",
    "CScore-Privacy Guarantees": "0.5",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    overall_score: "-7.25",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-8.5",
    "score-Identification and Residency Requirements": "-2",
    "score-Marginalized Access": "1",
    "score-Privacy Guarantees": "0",
    overall_confidence: "0.58695652173913",
  },
  SVK: {
    country: "Slovakia",
    overall_rank: "Closed Doors",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
    ],
    closed_categories: [
      "Undocumented Access",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    incomplete_categories: [],
    covid_performance: "worse",
    vax_percent: "45.6",
    vax_average: "68.1338888888889",
    vax_rank: "worse",
    deaths_per_100k: "242.9897",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "1130.9228979465",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.785714",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.636364",
    "CScore-Privacy Guarantees": "0.5",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "-7.75",
    "score-Policy Transparency": "2.25",
    "score-Undocumented Access": "-12.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "0.5",
    "score-Privacy Guarantees": "0",
    overall_confidence: "0.760869565217391",
  },
  ESP: {
    country: "Spain",
    overall_rank: "Open and Accessible",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: [
      "Undocumented Access",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    closed_categories: [
      "Policy Transparency",
      "Identification and Residency Requirements",
    ],
    incomplete_categories: ["Marginalized Access"],
    covid_performance: "better",
    vax_percent: "81.27",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "187.309",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "2380.6012207053",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "1",
    "CScore-Undocumented Access": "0.5",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.454545",
    "CScore-Privacy Guarantees": "0.666667",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    overall_score: "-0.5",
    "score-Policy Transparency": "1",
    "score-Undocumented Access": "-1.5",
    "score-Identification and Residency Requirements": "-2",
    "score-Marginalized Access": "1",
    "score-Privacy Guarantees": "1",
    overall_confidence: "0.673913043478261",
  },
  GBR: {
    country: "United Kingdom",
    overall_rank: "Open and Accessible",
    confidence_rank: "strong",
    completeness_of_documents: "complete",
    open_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Marginalized Access",
      "Privacy Guarantees",
    ],
    closed_categories: [],
    incomplete_categories: [],
    covid_performance: "worse",
    vax_percent: "72.94",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "208.619",
    study_deaths_average: "171.148022222222",
    deaths_rank: "worse",
    healthcare_spending_per_capita: "3754.4221289106",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "above",
    overall_confidence_rank: "strong",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.857143",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "1",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "strong",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "strong",
    "C-Privacy Guarantees": "strong",
    overall_score: "18.75",
    "score-Policy Transparency": "3.75",
    "score-Undocumented Access": "5.5",
    "score-Identification and Residency Requirements": "2",
    "score-Marginalized Access": "5.5",
    "score-Privacy Guarantees": "2",
    overall_confidence: "0.91304347826087",
  },
  MLT: {
    country: "Malta",
    overall_rank: "Closed Doors",
    confidence_rank: "weak",
    completeness_of_documents: "incomplete",
    open_categories: ["Privacy Guarantees"],
    closed_categories: [
      "Policy Transparency",
      "Undocumented Access",
      "Identification and Residency Requirements",
      "Marginalized Access",
    ],
    incomplete_categories: ["Undocumented Access", "Marginalized Access"],
    covid_performance: "better",
    vax_percent: "83.26",
    vax_average: "68.1338888888889",
    vax_rank: "better",
    deaths_per_100k: "89.5175",
    study_deaths_average: "171.148022222222",
    deaths_rank: "better",
    healthcare_spending_per_capita: "2395.5545581071",
    healthcare_spending_avg: "2913.27117279103",
    healthcare_rank: "below",
    overall_confidence_rank: "weak",
    "CScore-Policy Transparency": "0.909091",
    "CScore-Undocumented Access": "0.214286",
    "CScore-Identification and Residency Requirements": "1",
    "CScore-Marginalized Access": "0.272727",
    "CScore-Privacy Guarantees": "0.833333",
    "C-Policy Transparency": "strong",
    "C-Undocumented Access": "weak",
    "C-Identification and Residency Requirements": "strong",
    "C-Marginalized Access": "weak",
    "C-Privacy Guarantees": "strong",
    overall_score: "-5.75",
    "score-Policy Transparency": "-0.25",
    "score-Undocumented Access": "-7",
    "score-Identification and Residency Requirements": "0",
    "score-Marginalized Access": "-0.5",
    "score-Privacy Guarantees": "2",
    overall_confidence: "0.543478260869565",
  },
};

const questionsReshaped = (qData) => qData.map(q => {
  let {Yes, No, NA, Unknown,
    Yes_Score, No_Score, NA_Score, Unknown_Score,
     ...others} = q;
  // Yes = Yes.split(Object.keys({Yes})[0] + ", ")[1];
  // No = No.split(Object.keys({No})[0] + ", ")[1];
  // NA = NA.split(Object.keys({NA})[0] + ", ")[1];
  // Unknown = Unknown.split(Object.keys({Unknown})[0] + ", ")[1];
  const [minScore,maxScore] = extent([Yes_Score, No_Score, NA_Score, Unknown_Score]);

  return {
    ...others,
    answers: {Yes,No,NA,Unknown},
    answersScores: {
      Yes: Yes_Score,
      No: No_Score,
      NA: NA_Score,
      Unknown: Unknown_Score
    },
    scoreExtent: [minScore,maxScore],
    scoreNegMax: Math.abs(minScore),
    scorePosMax: Math.abs(maxScore),
  }
})

export const numCats = (new Set(
  countryData
  .filter(item => item["Cat_short"] === "subagg")
  .map(item => item["Score Type"])
)).size

export const data = {
  countryCodesArray: countryCodes.filter(d => !d.blank),
  countryProfiles: countryProfiles,
  countryDataNestToScore: nest()
    .key(d => d.Iso3)
    .key(d => d.Cat_short)
    .key(d => d["Score Type"])
    .object(countryData),
  countryData: nest()
    .key(d => d.Iso3)
    .key(d => d.Cat_short)
    .object(countryData),
  categoryData: nest()
    .key(d => d.Cat_short)
    .key(d => d.Iso3)
    .object(countryData),
  categorySubaggData: nest()
    .key(d => d["Score Type"])
    // .key(d => d["Score"])
    // .key(d => d.Iso3)
    .object(countryData.filter(d => d.Cat_short === "subagg")),
  countryToIsoLookup: Object
    .fromEntries(countryCodes
      .filter(d => !d.blank)
      .map(d => [d.country_name,d])
    ),
  isoToCountryLookup: Object
    .fromEntries(countryCodes
      .filter(d => !d.blank)
      .map(d => [d.iso3,d])),
  gridMax: {
    // x: max(countryCodes.map(d => d.grid_pos_x)), 
    x: 7, 
    y: max(countryCodes.map(d => d.grid_pos_x))
  },
  blanks: countryCodes.filter(d => d.blank),
  categoryScoresExtent: extent(
    countryData.filter(d => d.Cat_short === 'subagg')
      .map(d => d.Score)
  ),
  // questions: nest()
  //   .key(d => d["Score Type"])
  //   .object(questionsReshaped(questions)),
  questions: {
    'en': nest()
      .key(d => d["Score Type"])
      .object(questionsReshaped(questions)),
    'el': nest()
      .key(d => d["Score Type"])
      .object(questionsReshaped(questionsEL))
  }
}

export const countryFactsRetrievedOn = '2021-11-11'
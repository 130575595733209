import React from "react";

function PrivSimple(props) {
  const {fillPrimary} = props;
  const strokeWidth = props.strokeWidth;
  return (
    <g>
    {/* <path
      d="M12.075 4.731a5.307 5.307 0 01-3.953-2.044L8 2.517l-.123.17a5.306 5.306 0 01-3.952 2.044h-.15v3.74c0 3.4 4.13 4.976 4.172 4.992l.053.02.052-.02c.041-.016 4.173-1.592 4.173-4.992v-3.74zm-.15 3.74c0 2.989-3.456 4.498-3.925 4.69-.47-.192-3.924-1.701-3.924-4.69V5.03A5.495 5.495 0 008 3.018a5.495 5.495 0 003.924 2.01zM6.875 6.194v.84h-.115a.7.7 0 00-.699.7v2.476a.7.7 0 00.7.699h2.476a.7.7 0 00.699-.7V7.734a.7.7 0 00-.7-.7h-.114v-.839a1.123 1.123 0 00-2.247 0zm2.362 1.14a.4.4 0 01.398.4v2.476a.399.399 0 01-.398.398H6.76a.399.399 0 01-.398-.398V7.733a.4.4 0 01.398-.4h2.477zm-.416-1.14v.84H7.177v-.84a.822.822 0 111.645 0zM7.463 9.978h1.073v-.794a.795.795 0 00.277-.596.813.813 0 00-1.627 0 .792.792 0 00.277.595zM8 8.075a.504.504 0 01.295.918l-.06.045v.64h-.47v-.642l-.062-.045a.5.5 0 01-.216-.403A.513.513 0 018 8.075z"
      className="cls-1"
      style={{stroke:fillPrimary,strokeWidth:strokeWidth}}
    ></path> */}
    <path class="cls-1" d="M8.03253,13.21305h0S3.95785,11.69709,3.95785,8.362V4.77156A5.41873,5.41873,0,0,0,8.03253,2.66517a5.41873,5.41873,0,0,0,4.07468,2.10639V8.362c0,3.33511-4.07467,4.85107-4.07467,4.85107Z"
      style={{stroke:fillPrimary,strokeWidth:strokeWidth}}/>
    <rect class="cls-2" x="6.24529" y="7.07459" width="3.57447" height="3.57447" rx="0.54902"
      style={{stroke:fillPrimary,strokeWidth:strokeWidth}}/>
    <path class="cls-2" d="M8.03253,5.11214h0A.97246.97246,0,0,1,9.005,6.0846v.99a0,0,0,0,1,0,0H7.06006a0,0,0,0,1,0,0v-.99a.97246.97246,0,0,1,.97246-.97246Z"
      style={{stroke:fillPrimary,strokeWidth:strokeWidth}}/>
    <path class="cls-1" d="M8.69513,8.47861a.66262.66262,0,0,0-1.32524,0A.65047.65047,0,0,0,7.647,9.0031v.7155h.771V9.00317A.6507.6507,0,0,0,8.69513,8.47861Z"
      style={{stroke:fillPrimary,strokeWidth:strokeWidth}}/>
    </g>
  );
}

export default PrivSimple;
import { nest } from 'd3-collection';


const quotesRawWithoutId = [
  {
    "Country": "The Netherlands",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "It is very clear that undocumented people can and are being vaccinated",
    "RangeEquivalent": "++",
    "Comment": "People without citizenship number are approached through NGOs, churches, etc and it is openly communicated that they can access vaccinations",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "No, the policy and practices are the same everywhere in the country",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Very accessible: undocumented people have the right on paper to get vaccinated and in practice they are getting vaccinated",
    "RangeEquivalent": "++",
    "Comment": "The only challenge is to convince the community to come and to reach all, as we do not know how many people we are trying to reach.",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Yes: I think undocumented people are being vaccinated, but there are obstacles",
    "RangeEquivalent": "+",
    "Comment": "The biggest obstacles are reaching all with the information and the resistance to and lack of trust in vaccinations and authorities that make people not show up or actively refuse.",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Above and beyond: there are repeated messages, by authorities and on different platforms, ensuring that the privacy of people is respected and undocumented people will not be deported or reported / undocumented people are addressed and invited to take part in the vaccination efforts, with assurances about privacy guarantees",
    "RangeEquivalent": "++",
    "Comment": "",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's different: undocumented people have access to vaccines against COVID-19, but they cannot access other public healthcare services (other than emergency healthcare)",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "The Netherlands",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "The biggest struggle we face right now is how undocumented people can receive their vaccination certificate QR code, either on paper or online, to prove that they are fully vaccinated. Without this they are not allowed in restaurants and several public places, or they need to show a test not older than 24 hours. The authorities say that they are working on this but we see no progress.",
    "PersonName": "Msc Janine Wildschut",
    "PersonQualification": "Head of Medical Programs, Doctors of the World Netherlands",
    "PersonBio": "Janine Wildschut is a social scientist and currently the Head of Medical Programs at Doctors of the World Netherlands. Her life has been devoted to working with and for people who are excluded from society, both within and outside the Netherlands. She has worked in homeless shelters, researched drug use, and worked around HIV/AIDS with vulnerable groups."
  },
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "There are no official policies or statements, and undocumented people are excluded from (official?) vaccination efforts",
    "RangeEquivalent": "-",
    "Comment": "Undocumented people have been so far excluded from the vaccination efforts although on paper they can have access to COVID-19 vaccines.",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Greece",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Somewhat accessible: technically, undocumented people have the right to get vaccinated, but in practice it's not so straightforward",
    "RangeEquivalent": "+",
    "Comment": "Technically, undocumented people have the right to be vaccinated, but in practice it is not happening",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "No: I think some may be vaccinated, but the proportion is small",
    "RangeEquivalent": "-",
    "Comment": "",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Greece",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Not nearly enough: there have been some assurances, but they are inconsistent, not conclusive, and do not give a sense of certainty against reporting or deportation",
    "RangeEquivalent": "-",
    "Comment": "In some cases, in small towns and villages, public administrators called the police when undocumented people appeared asking where and how they could get vaccinated",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Greece",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people can access public healthcare and vaccines against COVID-19 through workarounds (for example, civil society initiatives, specific doctors, etc.)",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Rea-Nefeli Tzanetakou",
    "PersonQualification": "Campaign Coordinator, Intersos Hellas (Greece)",
    "PersonBio": "Rea-Nefeli is a Greek journalist, anthropologist and humanitarian. She has worked for more than 10 years in various media networks, until she joined the humanitarian sector."
  },
  {
    "Country": "Germany",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "There are no official policies or statements, and undocumented people are excluded from official vaccination efforts",
    "RangeEquivalent": "-",
    "Comment": "Undocumented migrants are not mentioned in official vaccination statements. Some local authorities are aware of the issue and take care of them on the local level or tolerate private initiatives. Generally undocumented migrants are excluded from the public health care system.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "Access to health care is difficult for undocumented migrants in generall all across Germany. Cities do not have the freedom to create pathways around national laws to implement a better access to health care. Vaccination for undocumented migrants is limited to the private initatives of GPs and in some regions to NGOs, if the state Government has supported the provision of anonimised health care in the past. In most regions, undocumented migrants are in danger of getting deported if they ask for vaccinations through the public health care system.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Not easily accessible: some undocumented people can get vaccinated, but it depends on circumstances and they have to find work-arounds",
    "RangeEquivalent": "-",
    "Comment": "There are very few possibilities to get vaccinated. If undocumented migrants don't want to risk to be deported they can't take advantage of the public health care system. However, it is possible to get the vaccination through private billing or in some cases as a donation through an NGO.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "No: I think some may be vaccinated, but the proportion is small",
    "RangeEquivalent": "-",
    "Comment": "There is no specific information provided for undocumented migrants. From the perspective of an undocumented migrant, each request for a vaccination raises the fear of deportation. The only protection against this is the personal confidentidality of doctors.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Not at all: there are no guarantees whatsoever, it is very likely that undocumented people are skipping their vaccine out of a valid fear of being deported / reported",
    "RangeEquivalent": "--",
    "Comment": "Vaccination centers didn't provide enough confidentiality. Since they closed in September, vaccinations are mainly given by GPs. Undocumented migrants are protected by their confidentiality, if the patients find a way to pay for the services without public support.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people can access public healthcare and vaccines against COVID-19 through workarounds (for example, civil society initiatives, specific doctors, etc.)",
    "RangeEquivalent": "",
    "Comment": "The residential act (§87 AufenthG) contains an obligation for all public services to denounce undocumented migrants if they take notice of their status. This is usually the case if undocumented migrants need financial support for medical services.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Germany",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "Policymakers in Germany tried to regulate all kinds of migration statuses in Germany in the past decades. For example, asylum seekers whose case has been denied are not undocumented migrants, they have documents, are registered and have acces to basic health care such as vaccinations. Nevertheless, there are estimates of at least 600,000 undocumented migrants all across Germany. But most policymakers are not aware of their existence.",
    "PersonName": "Christoph Krieger",
    "PersonQualification": "Sociologist, Medibuero Kiel",
    "PersonBio": "Christoph Krieger is a sociologist and has been working with Medibuero Kiel since 2016, assisting migrants with access to healthcare. He led several refugee shelters in Germany since 2015. He advises political parties, NGOs and international institutions such as the EU and the UN on access to healthcare for undocumented migrants."
  },
  {
    "Country": "Ireland",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "It is very clear that undocumented people can and are being vaccinated",
    "RangeEquivalent": "++",
    "Comment": "Since the start of the pandemic there has been a firewall between the Department of Health and immigration to allow undocumented people access health services safely. There was a clear statement from the Minister for Justice that undocumented people could access the vaccine safely and this has been the case.",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Very accessible: undocumented people have the right on paper to get vaccinated and in practice they are getting vaccinated",
    "RangeEquivalent": "++",
    "Comment": "At the beginning, when you needed a referral from doctor to prove your need for priority status for a vaccine, this was difficult for undocumented people to get this as many didn't have a pre existing relationship with a doctor. However, once the policy changed to vaccination by age the practice became smoother. Undocumented people with a social security number (PPS) can apply online in the normal way. Those without need to call the helpline and register over the phone in order to explain that they do not have a PPS. In practice we believe the vast majority of undocumented people have been able to get access to the vaccine.",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Absolutely yes: I think a large number of undocumented people have been vaccinated, or will be",
    "RangeEquivalent": "++",
    "Comment": "",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Enough: there are assurances and they are consistent enough that undocumented people can be confident that they won’t be reported / deported if they get the vaccine",
    "RangeEquivalent": "+",
    "Comment": "There was a firewall in place but only since the start of the pandemic. In normal times there is no firewall. So I would imagine most undocumented people don't fully understand the concept or how long it will last for and their levels of confidence in it would be hard to know. The firewall was not widely communicated. There was a clear statement from the Minister saying accessing the vaccine was safe for undocumented people but again this was not widely communicated to undocumented people. There was no big communications push or specific advertisements encouraging undocumented people. NGOs were the ones encouraging people to get the vaccine and that it was safe. We were supported by the Health Service in terms of providing clear factual information to share but not with extensive messaging coming from the Health Services themselves.",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people can access public healthcare and vaccines against COVID-19 through workarounds (for example, civil society initiatives, specific doctors, etc.)",
    "RangeEquivalent": "",
    "Comment": "In the normal run of things an undocumented person can technically access public health services but will be left with a large bill when they cannot prove their legal residence. Due to this and the fear of being reported to immigration, in practice they often don't access healthcare services even in extreme cases.",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "Ireland",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "We are seeing an improvement in the supports of undocumented people in Ireland. Access to the vaccines, Covid financial supports (albeit in very limited cases) and an upcoming regularisation scheme are all very welcome developments.",
    "PersonName": "Neil Bruton",
    "PersonQualification": "Campaigns Lead, Justice for the Undocumented, Migrant Rights Centre Ireland",
    "PersonBio": "Neil Bruton is Campaigns Lead with the Justice for the Undocumented (JFU) community in the Migrant Rights Centre Ireland. He splits his time between community development and migrant led campaigns with JFU."
  },
  {
    "Country": "United Kingdom",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "It is very clear that undocumented people can and are being vaccinated",
    "RangeEquivalent": "++",
    "Comment": "",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "No, the policy and practices are the same everywhere in the country",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Somewhat accessible: technically, undocumented people have the right to get vaccinated, but in practice it's not so straightforward",
    "RangeEquivalent": "+",
    "Comment": "Vaccine access has been predominantly through GP registration though when the vaccination rollout was at its peak, there were drop in/pop up clinics, which were run by a combination of local authorities, community groups, faith groups or charities. With GP registration, there have been barriers to accessing vaccination as some GP surgeries have been asking for proof of ID and/or address. In our experience, some undocumented migrants were struggling to register for several months with GPs in their area even with specialised advocacy support. As for drop in/pop up clinics, some required an NHS number, which acted as a barrier for undocumented migrants to access vaccination. We are also not sure whether drop in/pop up clinics were made available in some areas of England that could serve undocumented people who might not be registered with GPs. In light of these barriers, Public Health England published a letter by NHS England and NHS Improvement in August 2021, translated into 24 languages, reiterating the message that COVID-19 vaccination is free and available to all migrants in the UK regardless of status. The letter was published to be printed by anyone still struggling to access vaccination to take to GPs and vaccination walk-in centres or pop-up centres if they have been turned away from registering or getting the vaccine. It clearly explains how to access vaccination and that it is not a requirement to have a GP or an NHS number to get the COVID-19 vaccine, whilst also highlighting that proof of address or ID is not required to register with a GP.",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Yes: I think undocumented people are being vaccinated, but there are obstacles",
    "RangeEquivalent": "+",
    "Comment": "We think a substantial number of undocumented people are being vaccinated. This has been due to significant work and collaboration between public health, local and national authorities, community groups, charities or faith groups. However, the reality is that undocumented migrants still continue to face difficulties in registering with GPs, something that we observed from people we support. Additionally, there are and continue to be concerns amongst undocumented migrants that registering with a GP or accessing the vaccine would lead to their data being shared with the Home Office. Some undocumented people have had concerns that accessing vaccines is a trap due to mistrust in the practices of local authorities, government bodies and the Home Office.",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Above and beyond: there are repeated messages, by authorities and on different platforms, ensuring that the privacy of people is respected and undocumented people will not be deported or reported / undocumented people are addressed and invited to take part in the vaccination efforts, with assurances about privacy guarantees",
    "RangeEquivalent": "++",
    "Comment": "After the assurances from the Government and NHS England regarding undocumented migrants having access to COVID-19 vaccination, there have been continued efforts to reassure undocumented people from Government and various local authorities. This has included working with organisations such as Doctors of the World to produce translations about vaccination access, working with community groups that already have built trust with undocumented migrants to disseminate the information, and publicising this information in the media, on social media and on local authority and Government websites. However, in the UK, there is no firewall in place between public health services and the Home Office that manage immigration enforcement so it appears that undocumented migrants might still have some valid concerns and fears about accessing vaccination and health services in general.",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people can access public healthcare and vaccines against COVID-19 through workarounds (for example, civil society initiatives, specific doctors, etc.)",
    "RangeEquivalent": "",
    "Comment": "In the UK, undocumented people have access to vaccination against COVID-19 either through clinics or through their primary health care/GP. In terms of general access to health care, undocumented people can access primary health care free of charge but access to secondary healthcare i.e. hospital access including after being admitted through accident and emergency incurs charges. If they owe the hospital more than £500 after 6 months, this data is shared with the Home Office for enforcement purposes. During the pandemic, access to treatment for COVID-19 has been provided. There is an exemption on charges relating to the treatment for an initial illness, however, if there are any hospital admissions, treatment or stay for secondary illness resulting from COVID-19 such as conditions or complications resulting from ‘long-COVID’, charging comes into effect.",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "United Kingdom",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "From our perspective, there is still a gap between the policy and reality of access to COVID-19 vaccination for undocumented people. Whilst significant work has been done, it is clear that the hostile environment policies impact the ability of undocumented migrants to have confidence and trust in accessing health services which they are entitled to. This is particularly apparent in the difficulties that undocumented migrants have in registering with GPs, something that will need to be done in order to access vaccinations in the long-term (unless pop-up/walk-in clinics will also be used in the long-term) for as long as COVID-19 remains a threat to people's health and well-being in the years to come.",
    "PersonName": "Sunitha Dwarakanath",
    "PersonQualification": "Parliamentary Officer, Regularise",
    "PersonBio": "Sunitha Dwarakanath is the Parliamentary Officer at Regularise, a migrants founded grassroots collective and campaign group made up of migrants, British citizens and allies."
  },
  {
    "Country": "Czech Republic",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "There are no official policies or statements, but undocumented people are still being vaccinated by public authorities or through public funds",
    "RangeEquivalent": "+",
    "Comment": "The Health Ministry didn't explicitly put in the strategy, and there is no public statement on this, but they told the regions, which are running the vaccination efforts, that they should be responsible for the vaccination of socially excluded groups. A letter from the city of Prague stated that even migrants without legal residence can be included. It's written in very general terms. It can be questioned if migrants are undocumented but do not belong to the socially excluded group are included or not. Authorities are aware that they should vaccinate everybody but they didn't say it publicly.",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "Czech Republic",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "I don’t know / I can’t say",
    "RangeEquivalent": "",
    "Comment": "We know that there are some regions where they don't care at all about the vaccination of socially disadvantaged groups, but we didn't map it really. Prague is the most active, in other regions there are only few undocumented people. More mapping is needed.",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "Czech Republic",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Not easily accessible: some undocumented people can get vaccinated, but it depends on circumstances and they have to find work-arounds",
    "RangeEquivalent": "-",
    "Comment": "There is no public information or campaign on that. We are now working with the Prague region on how to spread information with the undocumented. At the moment only NGOs have access. Access is very limited and there is no way people can find out by themselves (it can't be found through a Google search).",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "Czech Republic",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "No: I think some may be vaccinated, but the proportion is small",
    "RangeEquivalent": "-",
    "Comment": "We don't really know. We are still at the beginning right now, and even in Prague, the focus so far has been on homeless people (some of them are undocumented) since around July. Now that it seems that this group is more or less covered, we are discussing how to reach out to undocumented migrants based on the experiences of NGOs working with homeless people - also using the structures that have been used for the homeless.",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "Czech Republic",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Not nearly enough: there have been some assurances, but they are inconsistent, not conclusive, and do not give a sense of certainty against reporting or deportation",
    "RangeEquivalent": "-",
    "Comment": "This is one of the main issues. We were assured repeatedly that it will be used only for medical reasons, but there is not a lot of trust. If our clients ask, we tell them that the risk is low, but it exists. For generating the certificate, an ID (any ID) and the name is needed. We didn't have a situation yet where a client didn't have any documents, so we don't know what could happen then.",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "Czech Republic",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: it's not possible for undocumented people to access public healthcare, nor to get vaccinated against COVID-19",
    "RangeEquivalent": "",
    "Comment": "It's completely connected. there is only access to emergency healthcare. Access is limited even for migrants with legal status (if migrants have a private health insurance, they would still need to pay for the vacccine). Undocumented people don't have access to public health insurance. If they had access to public health insurance, they would be vaccinated.",
    "PersonName": "Magda Faltová",
    "PersonQualification": "Director, Association for Integration and Migration",
    "PersonBio": "Magda Faltová is the Director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis."
  },
  {
    "Country": "France",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "It is very clear that undocumented people can and are being vaccinated",
    "RangeEquivalent": "++",
    "Comment": "Since the end of 2020, public authorities were very clear in their statements considering the vaccination of undocumented people. However, many undocumented people still find many obstacles to get vaccinated, and mainly those who live in slums or squats.",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Somewhat accessible: technically, undocumented people have the right to get vaccinated, but in practice it's not so straightforward",
    "RangeEquivalent": "+",
    "Comment": "Health promotion messages adapted to people with specific health needs or mistrust of institutional systems have to be developed and need more time and resources to be put in place (professional translators, healthcare mediation approach, services adapted to the living conditions of people). Those important measures are not always available.",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Yes: I think undocumented people are being vaccinated, but there are obstacles",
    "RangeEquivalent": "+",
    "Comment": "",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Not nearly enough: there have been some assurances, but they are inconsistent, not conclusive, and do not give a sense of certainty against reporting or deportation",
    "RangeEquivalent": "-",
    "Comment": "Even if there have been assurances (regarding protection against reporting or deportation or against the loss of a place in a shelter), there is lack of trust on the part of undocumented people.",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's different: undocumented people have access to vaccines against COVID-19, but they cannot access other public healthcare services (other than emergency healthcare)",
    "RangeEquivalent": "",
    "Comment": "As a global response to a sanitary emegency, access to vaccines was facilitated for all the population including undocumented people. However, access to vaccines and to other public healthcare services is still more difficult and complicated for undocumented people",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "France",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "The introduction of a \" health passport\" in August 2021 for the general population has accelerated the number of people who wanted to get access to vaccines but with more difficulties and obstacles for undocumented people.",
    "PersonName": "Dr Houda Merimi",
    "PersonQualification": "Health Advisor, Médecins du Monde France",
    "PersonBio": "Dr. Houda Merimi is MD and MPH. She has field experience in primary health care and community health, as well as expertise in issues related to health, precariousness and migrant's health."
  },
  {
    "Country": "Italy",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "There are no official policies or statements, but undocumented people are still being vaccinated by public authorities or through public funds",
    "RangeEquivalent": "+",
    "Comment": "Vaccine against Covid-19, as primary healthcare, is guaranteed for free in Italy to everyone residing on the Italian territory. Nonetheless no specific policy or information campaign was implemented in order to ensure that undocumented people were involved in the vaccination.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS24",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "In Italy the health system is regulated on a territorial basis. Some regions were more proactive and fast in figuring out solutions to override administrative barriers, some took many months to include undocumented people.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS25",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Somewhat accessible: technically, undocumented people have the right to get vaccinated, but in practice it's not so straightforward",
    "RangeEquivalent": "+",
    "Comment": "Although access to the vaccine is guaranteed to undocumented people, discriminatory practices remain in place in the exercise of this right. In fact, with the same health or age conditions, an undocumented person faces obstacles such as delays of months in the administration of the vaccine compared to a person with documents. Moreover, no information campaign aimed at foreign people was conducted and language barriers, in addition to the distrust due to the unchallenged spread of fake news within some communities, continue to represent an obstacle to joining the vaccination campaign.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS26",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Yes: I think undocumented people are being vaccinated, but there are obstacles",
    "RangeEquivalent": "+",
    "Comment": "When access to the vaccination campaign was extended to all categories, some undocumented people decided to get vaccinated, especially the ones with access to correct information. Afterwards, with the introduction of the vaccination mandate in the workplace, many people, especially those with precarious contracts such as those who do not have documents, have decided to get vaccinated for fear of losing their only income. However, the number of undocumented people present on the national territory is not known and it is difficult to establish how significant is the number of people who are vaccinated out of the total.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS27",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "There is already a firewall in place in the country, and undocumented people are aware that their privacy is respected and their immigration status is not questioned when they access public health services",
    "RangeEquivalent": "/",
    "Comment": "",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS28",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people can access public healthcare and vaccines against COVID-19 through workarounds (for example, civil society initiatives, specific doctors, etc.)",
    "RangeEquivalent": "",
    "Comment": "Based on the Italian constitution, people who are on the territory have the right to free access to basic health services regardless of their residence permit. This also applies to the Covid-19 vaccine. What happens in practice, however, is that a series of barriers of various kinds often intervene (language barriers, logistical barriers in reaching services, lack of information on one's rights, lack of awareness of one's health status) which mean that many people remain excluded from access to medical care as well as from the Covid-19 vaccine.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS29",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Italy",
    "Category": "Uncategorised",
    "CategoryDetail": "General comment",
    "Question": "",
    "Answer": "",
    "RangeEquivalent": "",
    "Comment": "Although for several undocumented people it was possible to get vaccinated, now many are experiencing difficulties in obtaining the green pass (vaccination certificate), for different reasons and not attributable to a single pattern. Currently in Italy the paradox has arisen whereby people vaccinated but with non-linear documentary situations are unable to obtain certification and risk being excluded from services such as public transport or not being able to work.",
    "PersonName": "Giulia Chiacchella",
    "PersonQualification": "Medical Coordinator, Ambulatorio Popolare INTERSOS30",
    "PersonBio": "Giulia Chiacchella is a medical doctor, working with humanitarian organizations since 2015 in projects related to migrants’ health. Since 2021 she works with INTERSOS as medical coordinator in the “Ambulatorio Popolare INTERSOS24” in Rome."
  },
  {
    "Country": "Spain",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How transparent are authorities in your country with regards to the vaccination of undocumented people against COVID-19?",
    "Answer": "It is very clear that undocumented people can and are being vaccinated",
    "RangeEquivalent": "++",
    "Comment": "The Spanish national strategy mentions that undocumented migrants and other vulnerable groups have to be vaccinated. (art. 3.6.7 National Strategy).",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  },
  {
    "Country": "Spain",
    "Category": "Uncategorised",
    "CategoryDetail": "Regional differences",
    "Question": "Are there differences across different regions in your country in terms of access to vaccination against COVID-19 for undocumented people?",
    "Answer": "There are some differences, but overall the policy and practice is homogeneous",
    "RangeEquivalent": "",
    "Comment": "There are some small differences since the vaccination booking system, for instance, is managed by each region. A national strategy, different regional vaccination plans.",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  },
  {
    "Country": "Spain",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "How accessible is, in your opinion, vaccination against COVID-19 for undocumented people in your country?",
    "Answer": "Somewhat accessible: technically, undocumented people have the right to get vaccinated, but in practice it's not so straightforward",
    "RangeEquivalent": "+",
    "Comment": "It's mandatory to have a health insurance number for the vaccination process. Although since 2018 the law allows access to the health system to undocumented migrants, there are still many without an health insurante number. In addition, there are some additional troubles, for instance, public information about the vaccination process can't reach vulnerable groups due to language barriers, among others.",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  },
  {
    "Country": "Spain",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Do you think that a substantial number of undocumented people in your country are being or will be vaccinated?",
    "Answer": "Yes: I think undocumented people are being vaccinated, but there are obstacles",
    "RangeEquivalent": "+",
    "Comment": "",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  },
  {
    "Country": "Spain",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "In your opinion, are there enough privacy/confidentiality guarantees in the vaccination efforts against COVID-19 to ensure that undocumented people are not reluctant to get the vaccine out of fear of being reported or deported?",
    "Answer": "Enough: there are assurances and they are consistent enough that undocumented people can be confident that they won’t be reported / deported if they get the vaccine",
    "RangeEquivalent": "+",
    "Comment": "Thanks to our data protection law, there is a clear prohibition for immigration authorities to get and use personal data from health care providers.",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  },
  {
    "Country": "Spain",
    "Category": "Uncategorised",
    "CategoryDetail": "Access to the vaccines vs. access to health care",
    "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
    "Answer": "It's the same: undocumented people have full access to public healthcare and to vaccination against COVID-19",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Rubén Romero Masegosa",
    "PersonQualification": "Human Rights Lawyer, CEPAIM",
    "PersonBio": "Rubén Romero Masegosa is a human rights lawyer working with the organisation CEPAIM."
  }
]

const quotesRawWithoutIdEL = [
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Πόσο διαφανείς είναι οι αρχές στη χώρα σας όσον αφορά τον εμβολιασμό ατόμων χωρίς έγγραφα κατά του COVID-19;",
    "Answer": "Δεν υπάρχουν επίσημες πολιτικές ή δηλώσεις και τα άτομα χωρίς έγγραφα αποκλείονται από τις (επίσημες;) προσπάθειες εμβολιασμού",
    "RangeEquivalent": "-",
    "Comment": "Άτομα χωρίς έγγραφα έχουν αποκλειστεί μέχρι στιγμής από τις προσπάθειες εμβολιασμού, αν και θεωρητικά μπορούν να έχουν πρόσβαση στα εμβόλια για τον COVID-19",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  },
  {
    "Country": "Greece",
    "Category": "Uncategorised",
    "CategoryDetail": "ΤΟΠΙΚΕΣ ΔΙΑΦΟΡΕΣ",
    "Question": "Υπάρχουν διαφορές μεταξύ των διαφόρων περιοχών της χώρας σας όσον αφορά την πρόσβαση στον εμβολιασμό κατά του COVID-19 για άτομα χωρίς έγγραφα;",
    "Answer": "Υπάρχουν κάποιες διαφορές, αλλά συνολικά η πολιτική και η πρακτική είναι ομοιογενείς",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  },
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Πόσο προσβάσιμος είναι, κατά τη γνώμη σας, ο εμβολιασμός κατά του COVID-19 για άτομα χωρίς έγγραφα στη χώρα σας;",
    "Answer": "Μερικώς προσβάσιμος: Κανονικά, άτομα χωρίς έγγραφα έχουν το δικαίωμα να εμβολιαστούν, αλλά στην πράξη δεν είναι τόσο απλό",
    "RangeEquivalent": "",
    "Comment": "Κανονικά, τα άτομα χωρίς έγγραφα έχουν δικαίωμα να εμβολιαστούν, αλλά στην πράξη δεν συμβαίνει",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  },
  {
    "Country": "Greece",
    "Category": "Undocumented Access",
    "CategoryDetail": "",
    "Question": "Πιστεύετε ότι ένας σημαντικός αριθμός ατόμων χωρίς έγγραφα στη χώρα σας εμβολιάζεται ή θα εμβολιαστεί;",
    "Answer": "Όχι: Νομίζω ότι κάποιοι μπορεί να είναι εμβολιασμένοι, αλλά η αναλογία είναι μικρή",
    "RangeEquivalent": "-",
    "Comment": "",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  },
  {
    "Country": "Greece",
    "Category": "Privacy Guarantees",
    "CategoryDetail": "",
    "Question": "Κατά τη γνώμη σας, υπάρχουν αρκετές εγγυήσεις προστασίας απορρήτου / εμπιστευτικότητας στις προσπάθειες εμβολιασμού κατά του COVID-19 για να διασφαλιστεί ότι τα άτομα χωρίς έγγραφα δεν διστάζουν να κάνουν το εμβόλιο από φόβο μήπως καταγγελθούν ή απελαθούν;",
    "Answer": "Ούτε καν αρκετά: Υπήρξαν ορισμένες διαβεβαιώσεις, αλλά είναι ασυνεπείς, όχι οριστικές και δίνουν μια αίσθηση αβεβαιότητας κατά της αναφοράς ή της απέλασης",
    "RangeEquivalent": "-",
    "Comment": "Σε ορισμένες περιπτώσεις, σε μικρές πόλεις και χωριά, οι κατά τόπο κυβερνώντες κάλεσαν την αστυνομία όταν εμφανίστηκαν άτομα χωρίς έγγραφα και ρωτούσαν που και πώς μπορούσαν να εμβολιαστούν",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  },
  {
    "Country": "Greece",
    "Category": "Uncategorised",
    "CategoryDetail": "ΠΡΟΣΒΑΣΗ ΣΤΑ ΕΜΒΟΛΙΑ ΕΝΑΝΤΙ ΠΡΟΣΒΑΣΗΣ ΣΤΗΝ ΥΓΕΙΟΝΟΜΙΚΗ ΠΕΡΙΘΑΛΨΗ",
    "Question": "Πώς συγκρίνεται η πρόσβαση σε εμβόλια για τον COVID-19 για άτομα χωρίς έγγραφα στη χώρα σας με τη γενική πρόσβαση τους στη δημόσια υγειονομική περίθαλψη;",
    "Answer": "Είναι το ίδιο: Άτομα χωρίς έγγραφα μπορούν να έχουν πρόσβαση στη δημόσια υγειονομική περίθαλψη και σε εμβόλια κατά του COVID-19 μέσω εναλλακτικών λύσεων (για παράδειγμα, πρωτοβουλίες της κοινωνίας των πολιτών, συγκεκριμένοι γιατροί κ.λπ.)",
    "RangeEquivalent": "",
    "Comment": "",
    "PersonName": "Ρέα-Νεφέλη Τζανετάκου",
    "PersonQualification": "Συντονίστρια Εκστρατείας, Intersos Hellas (Ελλάδα)",
    "PersonBio": "Η Ρέα-Νεφέλη Τζανετάκου είναι Ελληνίδα δημοσιογράφος και ανθρωπολόγος. Εργάστηκε για περισσότερα από 10 χρόνια σε διάφορα ΜΜΕ και στη συνέχεια απασχολήθηκε στον ανθρωπιστικό τομέα."
  }
]

export const quotesRaw = {
  'en': quotesRawWithoutId.map((q,i) => ({...q,id:i})),
  'el': quotesRawWithoutIdEL.map((q,i) => ({...q,id:i}))
} 

  // {
  //   "COUNTRY": "Czech Republic",
  //   "CATEGORY": "General access to healthcare",
  //   "EXPERT OPINION": "",
  //   "Question": "How does vaccine access for COVID-19 for undocumented people in your country compare to general access to public health care for undocumented people in your country?",
  //   "Answer": "It's the same: it's not possible for undocumented people to access public healthcare, nor to get vaccinated against COVID-19",
  //   "Related Quote": "It's completely connected. there is only access to emergency healthcare. Access is limited even for migrants with legal status (if migrants have a private health insurance, they would still need to pay for the vacccine). Undocumented people don't have access to public health insurance. If they had access to public health insurance, they would be vaccinated.",
  //   "EXPERT NAME": "Magda Faltová",
  //   "Qualification": "Director, Association for Integration and Migration (Sdružení pro integraci a migraci)",
  //   "EXPERT Bio": "Magda Faltová is the director of the Association for Integration and Migration (SIMI is a non-profit organization providing free counselling to migrants in the Czech Republic). She is also a member of the board of the European PICUM network (Platform of International Cooperation on Undocumented Migrants) and, on a national level, a chair of the Committee on Foreigners Rights of the Government Council for Human Rights and a chairwoman of the executive committee of the Consortium of Migrants Assis.",
  //   "NOTES": ""
  // },


export const quotesNest = {
  'en': nest()
    .key(d => d["Country"])
    .key(d => d["Category"])
    .object(quotesRaw['en']),
  'el': nest()
    .key(d => d["Country"])
    .key(d => d["Category"])
    .object(quotesRaw['el'])
}
